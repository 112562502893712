import React from 'react';
import {Link} from 'gatsby'

import StarIcon from '../../assets/images/star-icon.png'
import OurMissionShape from '../../assets/images/our-mission/our-mission-shape2.png'
import OurMissionImg from '../../assets/images/our-mission/our-mission11.png'
import OurMissionShape1 from '../../assets/images/our-mission/our-mission-shape1.png'

const OurMission = () => {
    return (
        <section className="our-mission-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-content">
                            <div className="content">
                                <span className="sub-title">
                               {/*      <img src={StarIcon} alt="image" />  */}
                               Reportería
                                </span>
                                <h2>Genera reportes de manera eficiente con los datos que necesites</h2>
                                <p>Mejorará la toma de decisiones de tu empresa con los siguientes beneficios</p>
                                <ul className="our-mission-list">
                                <li>
                                        <i className="flaticon-tick"></i>
                                        Mayor comunicación
                                       {/*  <img src={OurMissionShape} alt="Our Mission Shape" /> */}
                                    </li>         <li>
                                        <i className="flaticon-tick"></i>
                                        Incremento de la productividad
                                       {/*  <img src={OurMissionShape} alt="Our Mission Shape" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Mayor competitividad
                                       {/*  <img src={OurMissionShape} alt="Our Mission Shape" /> */}
                                    </li>


                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Precisión en el área de finanzas
                                       {/*  <img src={OurMissionShape} alt="Our Mission Shape" /> */}
                                    </li>

                                    
                                </ul>
                              {/*   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}

                 {/*                <Link to="/about-us-two" className="default-btn">
                                    <i className="flaticon-right"></i> Read More <span></span>
                                </Link> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            <img src={OurMissionImg} alt="image" />
                            <div className="shape">
                                <img src={OurMissionShape1} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurMission;