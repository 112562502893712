import React from 'react'
import { Link } from 'gatsby'
import img10 from '../../assets/images/about/about-img101.png'
import shape1 from '../../assets/images/about/about-shape1.png'
import shape2 from '../../assets/images/our-mission/our-mission-shape2.png'
import starIcon from '../../assets/images/star-icon.png'

const AboutUs = () => {
    return (
        <div className="about-area pb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={img10} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                 {/*    <img src={starIcon} alt="banner" />  */}
                                    Modulos
                                </span>
                                <h2>Personaliza tus procesos de manera eficiente y rápida</h2>
                                <p>Tendrás el mando de generar tus propios procesos de acuerdo a tus necesidades.</p>
                                <ul className="about-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Gestión iniciada
                                       {/*  <img src={shape2} alt="banner" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Observación
                                       {/*  <img src={shape2} alt="banner" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Revisión documentos
                                       {/*  <img src={shape2} alt="banner" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Envío de documentos

                                       {/*  <img src={shape2} alt="banner" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Validación
                                       {/*  <img src={shape2} alt="banner" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Gestión finalizada
                                       {/*  <img src={shape2} alt="banner" /> */}
                                    </li>
                                </ul>
                                <p>Mantén el control en todo momentos y administrarás tu tiempo de forma eficiente</p>
                        {/*         <Link to="/about-us" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Más acerca de nosotros <span></span>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs