import React from 'react'
import { Link } from 'gatsby'
/* import icon1 from '../../assets/images/icons/icon1.png'
import icon2 from '../../assets/images/icons/icon2.png'
import icon3 from '../../assets/images/icons/icon3.png' */
import starIcon from '../../assets/images/star-icon.png'
import shape2 from '../../assets/images/services/service-shape2.png'


import icon3 from '../../assets/images/icons/B_Web/5.png'
import icon2 from '../../assets/images/icons/B_Web/2.png'
import icon1 from '../../assets/images/icons/B_Web/1.png'



const FeaturedService = () => {
    return (
        <div className="boxes-area pb-70">
            <div className="container">

            <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="icon" /> 
                      Beneficios
                    </span>
                    <h2>Industrias en las que nos enfocamos</h2>
                  {/*   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                </div>


                
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-boxes-item">
                            <div className="icon">
                                <img src={icon1} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Optimización
                                </Link>
                            </h3>
                            <p>Mejora del seguimiento y control de procesos gracias a una normalización en la metodología de trabajo.</p>

                           {/*  <Link to="/service-details" className="default-btn">
                                <i className="flaticon-right"></i> 
                                Read More <span></span>
                            </Link> */}

                            <div className="shape1">
                                <img src={shape2} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-boxes-item">
                            <div className="icon">
                                <img src={icon2} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Productividad
                                </Link>
                            </h3>
                            <p>Mejora de la productividad general y eficiencia entre departamentos. Permite un ahorro en los tiempos de ejecución para la gran mayoría de los procesos.</p>
                            
                           {/*  <Link to="/service-details" className="default-btn">
                                <i className="flaticon-right"></i> 
                                Read More <span></span>
                            </Link> */}

                            <div className="shape1">
                                <img src={shape2} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                        <div className="single-boxes-item">
                            <div className="icon">
                                <img src={icon3} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Fiabilidad
                                </Link>
                            </h3>
                            <p>Muchos de los problemas que pueden surgir dentro de un proceso de trabajo son conocidos por anticipado, por tanto, el esquema de flujo de trabajo permite adelantarnos a posibles fallos y anticipar las soluciones.</p>
                            
                           {/*  <Link to="/service-details" className="default-btn">
                                <i className="flaticon-right"></i> 
                                Read More <span></span>
                            </Link> */}

                            <div className="shape1">
                                <img src={shape2} alt="banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturedService